export enum ResourseNames {
  therapists = 'therapists',
  therapistsRequests = 'therapists/requests',
  therapistsProfileUpdates = 'therapists/profile-updates',
  patients = 'patients',
  companies = 'b2b/companies',
  companiesRequests = 'b2b/requests',
  diseases = 'diseases',
  diseasesGroup = 'diseases/group',
  therapyMethods = 'therapy_methods',
  faqGroup = 'faq/group',
  faqQuestions = 'faq/question',
  statisticsSessions = 'statistics/sessions',
  statisticsPromotedSessions = 'statistics/sessions-promoted',
  statisticsPromocodes = 'statistics/therapists/promocodes',
  reusablePromocodes = 'promocodes',
  apiKeys = 'api-keys',
  webhooks = 'webhooks',
  searchStatistics = 'search-statistics',
  statisticsClients = "statistics/clients",
  currencies = 'payment-gateway/currencies'
}

export interface Query<T> {
  isLoading?: boolean;
  data?: T extends {} ? T : any;
  error?: any;
}
